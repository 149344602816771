import React from "react"

import SEO from "../../components/seo"

import Layout from "../../components/layout"
import { Link } from 'gatsby'

const AboutPage = () => {
    return (
        <Layout>
            <SEO title="Apps" />
            <div className='p-12 text-center'>
                <h1 className='text-4xl font-bold'>Atlee Biotech, Inc.</h1>
            </div>
            <div className='flex justify-center text-center px-48'>

                {/*<h1 className='text-xl text-center'>Base subscription of $50/month + fee per use</h1>*/}
                <p className='text-xl'>
                    Atlee Biotech, Inc. was founded out of the need to develop novel treatments
                    for acute disorders. Atlee Biotech, Inc. is committed to helping people live healthier and live longer.
                {/*    <br /><br />*/}
                {/*    <span className='text-2xl font-bold'>Some of our current projects include:</span>*/}
                {/*    <br />*/}
                {/*    <a href='https://patents.google.com/patent/US20170135984A1/en'*/}
                {/*                                             className='text-blue-600'>*/}
                {/*        US Patent on Intranasal and transdermal*/}
                {/*    administration of kappa-opioid-receptor agonists: salvinorin a for the treatment of*/}
                {/*    neuropsychiatric and addictive disorders.*/}
                {/*</a>*/}
                </p>
            </div>
        </Layout>
    )
}

export default AboutPage
